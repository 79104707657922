*{
  padding: 0px;
  margin: 0px;
}
.logoss{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  bottom: 0;
  background: red;
  background-image:url('./images/logo.png') ;
  background-repeat: no-repeat;
  background-size:80% 65%;
  background-position: center;
}
.bg-red{
  background-color: red;
  color: white;
}
.bg-img{
  height: 100vh;
  background-image: url('./images/banner4.jpg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-Book{
  height: 60vh;
  background-image: url('./images/banner2.jpg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-100{
  margin-top: 100px;
}
.white{
  color: white !important;
}
.h1{
  letter-spacing: 5px;
  font-size: 70px;
}
.bg-color{
  background-color: red;
}
.glowing-circle {
  width: 100%;
  height: 300px;
  border-radius:20px;
  background-color: #fff;
  box-shadow: 0 0 5px 7px red;
  }
  .btnho-sh{
   background-color:#212529;
   color: #fff;
   border-radius: 20px;
   border: 3px solid white;
   font-size: 26px;
   transition: 0.6s ease-in-out;
  }
  .btnho-sh:hover{
    border: 3px solid red ;
    background-color:#fff ;
    color: red;
  }
  .book-button{
    position: relative;
    bottom: -170px;
  }

  @media only screen and (max-width: 768px) {
    .book-button{
      position: relative;
      bottom: -85px;
    }
    .h1{
      letter-spacing: 5px;
      font-size: 50px;
    }
    .bg-img{
      height: 80vh;
      background-image: url('./images/banner.jpg');
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logoss{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      bottom: 0;
      background: red;
      background-image:url('./images/logo.png') ;
      background-repeat: no-repeat;
      background-size: 40% 50%;
      background-position: center;
    }
  }
  @media only screen and (max-width: 425px) {
    .book-button{
      position: relative;
      bottom: -90px;
    }
    .h1{
      letter-spacing: 5px;
      font-size: 32px;
    }
    .bg-img{
      height: 80vh;
      background-image: url('./images/banner.jpg');
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logoss{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      bottom: 0;
      background: red;
      background-image:url('./images/logo.png') ;
      background-repeat: no-repeat;
      background-size: 80% 45%;
      background-position: center;
    }
  }
  @media only screen and (max-width: 320px) {
    .book-button{
      position: relative;
      bottom: -70px;
    }
    .h1{
      letter-spacing: 5px;
      font-size: 26px;
    }
    .bg-img{
      height: 80vh;
      background-image: url('./images/banner.jpg');
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logoss{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      bottom: 0;
      background: red;
      background-image:url('./images/logo.png') ;
      background-repeat: no-repeat;
      background-size: 80% 45%;
      background-position: center;
    }
  }

  @media only screen and (max-width: 460px) {
    .h1{
      letter-spacing: 5px;
      font-size: 26px;
    }
    .bg-img{
      height: 80vh;
      background-image: url('./images/banner.jpg');
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logoss{
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      bottom: 0;
      background: red;
      background-image:url('./images/logo.png') ;
      background-repeat: no-repeat;
      background-size: 80% 45%;
      background-position: center;
    }
  }